








































































































































































































































































































































































































































































































































































.create-broadcast {
  .flatpickr-input {
    background: #FFF !important;
  }

  .create-broadcast-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .builder {
      flex: 0 0 600px;
      max-width: 600px;
    }

    .preview {
      flex: 1 1 auto;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      max-width: 1000px;

      .preview-inner {
        position: fixed;
        top: 120px;
        height: 800px;
        width: 320px;
        background-image: url('~~~@assets/images/mobile-screen.png');
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 80px 0px 80px 45px;
        text-align: left;
        font-size: 13px;

        .help-icon {
          position: absolute;
          right: -20px;
          top: 40px;
        }

        .preview-block {
          position: relative;
          display: inline-block;
          padding: 10px 10px;
          color: #4b4b5a;
          background: #e2e7f1;
          border-radius: 1rem;
          border-bottom-left-radius: 0;
          cursor: pointer;
          max-width: 85%;

          &::after {
            position: absolute;
            bottom: 0;
            right: 99%;
            width: 0;
            height: 0;
            margin-left: -1px;
            pointer-events: none;
            content: ' ';
            border: solid transparent;
            border-width: 6px;
            border-bottom-color: #e2e7f1;
            border-right-color: #e2e7f1;
          }
        }
      }
    }
  }
}
